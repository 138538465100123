/* Timeline.css */
.timeline-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.timeline-container h1 {
  margin-bottom: 20px;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline-event {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.timeline-icon {
  background: #61dafb;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 20px;
}

.timeline-content {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.timeline-event h2 {
  margin-bottom: 5px;
}

.timeline-event p {
  margin: 0;
}
