nav {
  background: white;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.top-row, .bottom-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 50px;
  margin-right: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px 0 0 20px;
  outline: none;
}

.search-bar button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background: #61dafb;
  color: white;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
}

.search-bar button:hover {
  background: #21a1f1;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

ul li {
  margin: 0 20px;
  position: relative;
    /* Prevent text from wrapping to the next line */
}

ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s, transform 0.3s;
  font-size: 18px;
}

ul li a.active {
  color: #61dafb;
  border-bottom: 2px solid #61dafb;
}

ul li a:hover {
  color: #61dafb;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.bottom-row ul {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.bottom-row ul li {
  margin: 0 10px;
}

.bottom-row ul li a {
  font-size: 16px;
}
