/* Footer.css */
.footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 20px;
  border-top: 5px solid #fff;
  width: 100%;
  position: relative;
  bottom: 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.footer a {
  text-decoration: none;
  color: white;
}

.footer a:hover {
  text-decoration: underline;
}
