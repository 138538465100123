/* App.css */
body {
  padding-top: 70px; /* Adjust this value to ensure it accommodates the navigation bar */
  font-family: 'Roboto', sans-serif;
  margin: 0;
  box-sizing: border-box;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
  flex: 1;
}

.grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

button {
  margin: 5px;
}

footer {
  margin-top: auto;
}
