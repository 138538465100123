.compostle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
}

.game-info {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  font-size: 18px;
}

.bin-images {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust spacing between bins if needed */
  width: 100%;
  max-width: 600px;
}

.bin {
  width: 120px;
  height: 120px;
  margin: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

.correct {
  border: 4px solid limegreen;
}

.incorrect {
  border: 4px solid red;
}

.highlight-correct {
  border: 4px solid limegreen;
}

.bin img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.compost-bin {
  background-color: #d4edda;
}

.trash-bin {
  background-color: #f8d7da;
}

.recycling-bin {
  background-color: #d1ecf1;
}
