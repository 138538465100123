body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f9f9f9;
}

.about-container {
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-section {
  text-align: center;
  padding: 50px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.hero-image {
  width: 100%;
  max-width: 600px;
  height: auto;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.sidebar {
  position: relative;
  transition: transform 0.3s ease-out;
  top: 20px;
  flex: 1;
  min-width: 200px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-right: 20px;
  max-height: calc(100vh - 40px); /* Ensure it doesn't exceed viewport height */
  overflow: auto; /* Add scrolling if content overflows */
}

.sidebar ul {
  list-style: none;
  padding: 0;
  display: block !important; /* Override flex display */
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar ul li button {
  display: block;
  width: 100%;
  padding: 10px;
  background: #61dafb;
  color: white;
  border: none;
  cursor: pointer;
  text-align: left;
  border-radius: 5px;
}

.sidebar ul li button:hover {
  background: #21a1f1;
}

.main-content {
  flex: 3;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}

.project-details, .website-info, .project-goals, .data-submission, .funding {
  margin: 40px 0;
}

.composter-images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.composter-images img, .data-image, .carrot-image, .data-submission-image, .funding-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  display: block;
  margin: 10px auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 20px;
  border-top: 5px solid #fff;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.footer a {
  text-decoration: none;
  color: white;
}

.footer a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .project-details, .website-info, .project-goals, .data-submission, .funding {
    padding: 50px;
  }
}
