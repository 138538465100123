@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  padding-top: 100px; /* Adjusted value to ensure it accommodates the navigation bar */
  font-family: 'Roboto', sans-serif;
  margin: 0;
  box-sizing: border-box;
  background: #f9f9f9;
}

.plant-container {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
}

.dropdown-button-wrapper {
  position: relative;
  display: inline-block;
}

.data-submission-menu {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px 20px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.hidden {
  display: none;
}





.graphs-section {
  margin-bottom: 20px;
}

.data-entry {
  background: white;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.data-entry h2 {
  color: #333;
  margin-bottom: 20px;
}

.data-entry form {
  display: flex;
  flex-direction: column;
}

.data-entry label {
  margin-bottom: 10px;
}

.data-entry input,
.data-entry select,
.data-entry textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.data-entry button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  margin: 10px 2px;
  border-radius: 5px;
  cursor: pointer;
}

.data-entry button:hover {
  background-color: #21a1f1;
  transform: translateY(-2px);
}

.data-reset-import {
  background: white;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.data-reset-import h2 {
  color: #333;
  margin-bottom: 20px;
}

.data-reset-import label {
  margin-bottom: 10px;
}

.data-reset-import button {
  width: 100%;
  padding: 10px;
  background: #61dafb;
  color: white;
  border: none;
  cursor: pointer;
  text-align: left;
  border-radius: 5px;
}

.data-reset-import button:hover {
  background: #21a1f1;
}

footer {
  background: linear-gradient(to right, #61dafb, #21a1f1);
  color: white;
  text-align: center;
  padding: 20px;
  border-top: 5px solid #fff;
}

@media (min-width: 768px) {
  .plant-container {
    padding: 40px;
  }
  .data-entry,
  .data-reset-import {
    padding: 40px;
  }
}
