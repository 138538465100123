/* Home.css */
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.hero-section {
  text-align: center;
  padding: 50px 20px;
  background: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.hero-section h1 {
  margin-bottom: 20px;
}

.hero-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
}

.chart-wrapper {
  display: flex;
  justify-content: center;
}

.content {
  padding: 20px;
}
.content-home {
  padding: 20px;
}


.introduction, .graph-section, .gallery {
  margin-bottom: 40px;
}

.gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.gallery-item {
  margin: 20px;
  text-align: center;
}

.gallery-item img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
