/* Compostle.css */
.compostle-container {
  text-align: center;
  padding: 20px;
}

.game-info {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  font-size: 18px;
}

.game-board {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.item {
  width: 150px;
  height: 150px;
  border: 2px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: grab;
}

.bins {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 600px;
}

.bin {
  width: 200px;
  height: 300px;
  border: 2px solid #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}

.bin img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.compost-bin {
  background-color: #d4edda;
}

.trash-bin {
  background-color: #f8d7da;
}

@media (min-width: 768px) {
  .game-board {
    flex-direction: row;
  }
}
