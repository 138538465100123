/* Style for the advanced gallery container */
.advanced-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Style for each advanced photo container */
.advanced-photo {
  flex: 1 1 30%;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: transform 0.2s;
}

.advanced-photo:hover {
  transform: scale(1.05);
}

/* Style for each advanced image */
.advanced-photo img {
  width: 100% !important;
  max-width: 300px !important;
  height: auto !important;
  max-height: 200px !important;
  object-fit: cover !important;
}

/* Style for the advanced photo information */
.advanced-info {
  padding: 10px;
  text-align: center;
}

.advanced-info h2 {
  margin: 5px 0;
  font-size: 1.2em;
}

.advanced-info p {
  margin: 2px 0;
  color: #555;
}

/* Style for the modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.9); /* Black background with opacity */
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

@-webkit-keyframes zoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

@keyframes zoom {
  from {transform: scale(0.1)} 
  to {transform: scale(1)}
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
